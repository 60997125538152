import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Button from 'react-bootstrap/Button';
import ToggleButton from 'react-bootstrap/ToggleButton'

const Header = ({ siteTitle }) => {

  const [show, setShow] = React.useState(false);

  const toggleButton = () => {
    if(show)
      setShow(false)
    else
      setShow(true)
  }
  return (
      <header className="container-fluid">
        <div className="row header-bar">
          <div className="name col-sm-3">
            <Link to="/"><h3>Kreston Shirley</h3></Link>
          </div>
          <div className="menu col-sm-6">
            <nav className="navbar">
              <ul className="navbar-nav flex-row">
                <li className="nav-item">
                  <Link className="nav-link" href="/#about-me-cont/">About</Link>
                </li>
                {/*<li className="nav-item">*/}
                {/*  <a className="nav-link" href="https://kesconsulting.tech/">Developer</a>*/}
                {/*</li>*/}
                <li className="nav-item">
                  <Link className="nav-link" to="/gaming-work/">Gaming Work</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/#hobbies-section/">Hobbies</Link>
                </li>
              </ul>
            </nav>
          </div>
          <div className="social-links-header col-sm-3">
            <a className="social-links" href="https://www.linkedin.com/in/krestonshirley/"><i className="fa fa-linkedin-square"></i></a>
            <a className="social-links" href="https://twitter.com/krestonshirley"><i className="fa fa-twitter"></i></a>
            <a className="social-links" href="https://www.instagram.com/krestonshirley/"><i className="fa fa-instagram"></i></a>
          </div>
        </div>
        <div className="mobile-menu-group row">
          <div id="mobile-menu" className="col-sm-12 d-flex justify-content-between">
            <a className="navbar-brand" href="#">Kreston Shirley</a>
            <Button variant="secondary" onClick={toggleButton}><i className="fa fa-bars fa-1x"></i></Button>
          </div>
          <div id="mobile-menu-collapse" className="col-sm-12">
            <nav className="navbar col">
              <div className= {(show ? "" : "collapse")}>
                <ul className="navbar-nav flex-column">
                  <li className="nav-item">
                    <Link className="nav-link" href="/#about-me-cont">About</Link>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="https://kesconsulting.tech/">Developer</a>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/gaming-work">Gaming Work</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/#hobbies-section">Hobbies</Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
