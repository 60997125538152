import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Footer = () => (
    <footer className="container-fluid">
      <div className="row">
        <div className="col-sm-12 footer-row">
          <div className="col footer-section">
            <h3>Kreston Shirley</h3>
          </div>
          {/*<div className="col footer-section">*/}
          {/*  /!*<nav className="footer-nav">*!/*/}
          {/*  /!*  <ul className="navbar-nav flex-row">*!/*/}
          {/*  /!*    <li><a href="#about-me-cont">About</a></li>*!/*/}
          {/*  /!*    <li><a href="#leadership-row">Leadership</a></li>*!/*/}
          {/*  /!*    <li><a href="#kes-consulting">Dev Work</a></li>*!/*/}
          {/*  /!*    <li><a href="#notserk-gaming">Gaming</a></li>*!/*/}
          {/*  /!*  </ul>*!/*/}
          {/*  /!*</nav>*!/*/}
          {/*</div>*/}
          <div className="col footer-section">
            <div>
              <span>
                <a className="social-links" href="https://www.linkedin.com/in/krestonshirley/"><i className="fa fa-linkedin-square"></i></a>
                <a className="social-links" href="https://twitter.com/krestonshirley"><i className="fa fa-twitter"></i></a>
                <a className="social-links" href="https://www.instagram.com/krestonshirley/"><i className="fa fa-instagram"></i></a>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div id="copyright-row" className="row">
        <div className="col-sm-12">
          <p>Copyright 2021 krestonshirley.com All Rights Reserved.</p>
        </div>
      </div>
    </footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
